import React from 'react'
import './css/TechniqueTable.css'

const MiscTechniques = () =>
{
    return (
        <div className="CenterIt">
        <p>More skills</p>   
            <div>
            </div>
            <br></br>
            <div>
                <table>
                    <tbody>
                    <tr>
                    <th>Technique</th>
                    <th>Classification (1/10)</th>
                    </tr>
                    <tr>
                    <td>CQRS / Events</td>
                    <td>5</td>
                    </tr>
                    <tr>
                    <td>CI / CD</td>
                    <td>5</td>
                    </tr>
                    <tr>
                    <td>Unit testing</td>
                    <td>7</td>
                    </tr>
                    <tr>
                    <td>Front end testing</td>
                    <td>4</td>
                    </tr>
                    <tr>
                    <td>Integration test</td>
                    <td>4</td>
                    </tr>
                    <tr>
                    <td>React</td>
                    <td>6</td>
                    </tr>
                    <tr>
                    <td>Angular</td>
                    <td>4</td>
                    </tr>
                    <tr>
                    <td>Python</td>
                    <td>3</td>
                    </tr>
                    <tr>
                    <td>Machine Learning</td>
                    <td>2</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>);
}

export default MiscTechniques;