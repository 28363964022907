import * as React from "react";
import SkillBar from "react-skillbars";

const skills = [
  {
    type: "C#",
    level: 70,
  },
  {
    type: "SQL",
    level: 70,
  },
  {
    type: "Cloud",
    level: 65,
  },
  {
    type: "Javascript",
    level: 60,
  },
  {
    type: "Typescript",
    level: 60,
  },
  {
    type: "Scrummaster",
    level: 60,
  },
  {
    type: "Docker",
    level: 30,
  },
  {
    type: "NoSQL",
    level: 20,
  },
];

const colors = {
  bar: {
    hue: 200,
    saturation: 50,
    level: {
      minimum: 20,
      maximum: 75,
    },
  },
  title: {
    text: "#fff",
    background: "#2980b9",
  },
};

const Techniques = () => (
  <div>
    <p style={{ color: "#fff" }}>Skills</p>
    <SkillBar skills={skills} colors={colors} />
    <br></br>
  </div>
);

export default Techniques;
