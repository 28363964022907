
import "./css/TechniqueBubbles.css";

const TechniqueBubbles = () => (
    <div >
            <span className="bubble" >.Net</span>
            <span className="bubble" >javascript</span>
            <span className="bubble" >MS SQL</span>
            <span className="bubble" >C#</span>
            <span className="bubble" >MES</span>
            <span className="bubble" >OPC UA</span>
    </div>
);

export default TechniqueBubbles;