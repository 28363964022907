import Techniques from './techniques';
import Experience from './experience';
import SocialIcons from './social-icons';
import MiscTechniques from './MiscTechniques';
import PdfView from './pdf/PdfView';
import About from './about';
import TechniqueBubbles from './techniqueBubbles';

const Profile = () => (
    <div>
        <About/>
        <TechniqueBubbles/>
        <PdfView/>
        <Experience/>
        <Techniques/>
        <MiscTechniques/>
        <br></br>
        <SocialIcons/>
        <br></br>
    </div>
);

export default Profile;