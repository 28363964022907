import * as React from "react";
import "react-vertical-timeline-component/style.min.css";
import "./icons/Experience.css";
import iconDev from "./icons/icon-developer.jpg";
import iconStudy from "./icons/study.png";
import iconStar from "./icons/star.png";
import lindify from "./icons/lindify_logo.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
// import { useState } from "react";

const Experiences = () => {

  // const [isHovering, setIsHover] = useState(false);

  // const handleMouseEnter = () => {
  //    setIsHover(true);
  // };
  // const handleMouseLeave = () => {
  //    setIsHover(false);
  // };
return (
  <div>
    <p style={{ color: "#fff" }}             
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            >Experiences </p>
    {/* {isHovering && (
          <div>
            <h2>Only visible when hovering div</h2>
            <h2>bobbyhadz.com</h2>
          </div>
        )} */}

    <VerticalTimeline>
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        date="2023 sept - current"
        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        icon={
          <img
            alt=""
            className="vertical-timeline-element-icon"
            src={lindify}
          />
        }
      >
        <h2 className="vertical-timeline-element-title">Lindify IT AB</h2>
        <h3 className="vertical-timeline-element-subtitle">
          CEO, software engineer, software architect
        </h3>
        <p>Missions in:</p>
          <hr/>
        <div style={ {textAlign:"left"}} >
          
          <div className="vertical-timeline-element-title">
            - Scania AB (2023 sept - current) <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Tech lead, Software architect, MES architect</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >Angular</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>
            <span className="spanClass" >MES</span>
            <span className="spanClass" >OPC UA</span>
          </div>
          <p></p>
        </div>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        date="2017 sept - 2023 sept"
        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        icon={
          <img
            alt=""
            className="vertical-timeline-element-icon"
            src={iconDev}
          />
        }
      >
        <h2 className="vertical-timeline-element-title">Sogeti AB</h2>
        <h3 className="vertical-timeline-element-subtitle">IT consultant</h3>
        <b>Developer, architect</b>
        <p>Missions in:</p>
          <hr/>
        <div style={ {textAlign:"left"}} >
          
          <div className="vertical-timeline-element-title">
            - Scania AB (2023 jan - 2023 sept) <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Tech lead, Software architect</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >Angular</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>
          </div>
          <hr/>
          <div className="vertical-timeline-element-title">
            - Government (2022-okt - 2023 jan) <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Senior software engineer</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>
            <span className="spanClass" >Integrations</span>
          </div>
          <hr/>
          <div className="vertical-timeline-element-title">
           - Parental leave (2022-may - 2022 okt) <br></br>
          </div>
          <hr/>
          <div className="vertical-timeline-element-title">
            - Ericsson AB (2020 jan - 2022 april) <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Senior software engineer, scrum master</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>
            <span className="spanClass" >React</span>
            <span className="spanClass" >Typescript</span>
            <span className="spanClass" >Azure</span>
            <span className="spanClass" >CQRS</span>
            <span className="spanClass" >Event sourcing</span>
          </div>
          <hr/>
          <div className="vertical-timeline-element-title">
            - Husqvarna Group (2017-2020 jan + 2022 april - may) <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Software engineer</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>
            <span className="spanClass" >MES</span>
          </div>
          <p></p>
        </div>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        date="2012 - 2017"
        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        icon={
          <img
            alt=""
            className="vertical-timeline-element-icon"
            src={iconStar}
          />
        }
      >
        <h2 className="vertical-timeline-element-title">Nyce Solutions AB</h2>
        <h3 className="vertical-timeline-element-subtitle">
          WMS Application consultant/developer
        </h3> <br></br>
            <b>&nbsp; &nbsp; Roles: </b><>Developer, application consultant</><br></br>
            <b>&nbsp; &nbsp; Technologies: </b>
            
            <span className="spanClass" >.Net</span>
            <span className="spanClass" >MS SQL</span>
            <span className="spanClass" >C#</span>

      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        contentArrowStyle={{ borderRight: "7px solid  rgb(33, 150, 243)" }}
        date="2010 - 2012"
        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
        icon={
          <img
            alt=""
            className="vertical-timeline-element-icon"
            src={iconStudy}
          />
        }
      >
        <h2 className="vertical-timeline-element-title">Högskolan i Borås</h2>
        <h3 className="vertical-timeline-element-subtitle">
          University studies
        </h3>
        <p>Dataekonomi 3 years of study</p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </div>

);
}


export default Experiences;
