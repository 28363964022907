import React from 'react';
import image from './profile/images/chlind_sm.jpg';
import './App.css';
import Profile from './profile/profile';

function App() {
  return (
    <div className="App">
      <img src={image} className="App-logo" alt=''/>
      <Profile/>
    </div>
  );
}

export default App;
