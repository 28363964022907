import "./css/About.css";

const About = () => (
    <div className="CenterItBlock">
        <div className="CenterItText">
            <div className="left">
                I am an experienced IT consultant with a passion for solving complex problems and creating elegant solutions. 
                <br/><br/>
                <>With over 11 years in the industry, I’ve mastered the art of programming and system architecture.</>
                <br/>
                My approach is client-centric, focusing on understanding business needs and delivering reliable results. Whether it’s debugging code or designing scalable systems, I thrive on challenges.

                Let’s collaborate and turn technology into success!
            </div>
        </div>
    </div>
);

export default About;