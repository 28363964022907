
import React from 'react';
import CvPdf from '../cv/Christoffer-Lind-cv.pdf';
import email from "../icons/email-icon-vector.jpg";



const PdfView = () => (
  <div>
      <a href = {CvPdf} download='Christoffer-Lind-cv.pdf'>
        <button type = "button"> Download CV </button>
      </a>
      
      <a href="mailto:Christoffer Lind(christoffer@lindify.se)?subject=I%20would%20like%20contact...&body=Hello%20Christoffer,%20%2C%0D%0A%20We%20are%20...%20and%20we%20would%20like%20to%20...">
         <button type = "button"> Email me </button>{/*< img alt="" className="experience" src={email} /> */}
      </a>
    </div>
  );


export default PdfView;