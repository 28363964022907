import React from "react";
import "./icons/Experience.css";
import iconLinkedin from "./icons/linkedin_icon.png";
import iconTwitter from "./icons/twitter-blue.png";
import iconGithub from "./icons/github-blur.png";
// import email from "./icons/email-icon-vector.jpg";

const SocialIcons = () => (
  <div>
    <a href="https://www.linkedin.com/in/christoffer-lind/">
      <img alt="" className="experience" src={iconLinkedin} />
    </a>
    <a href="https://github.com/lindchristoffer">
      <img alt="" className="experience" src={iconGithub} />
    </a>
    {/* <a href="mailto:christoffer@lindify.se">
      <img alt="" className="experience" src={email} />
    </a> */}
  </div>
);

export default SocialIcons;
